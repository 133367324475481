.sbContainer {
  align-items: center;
  max-height: 100%;
  width: 100%;
}

.sbH {
  height: 14.81%;
  width: 100%;
  margin-top: 10%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldBold;
  letter-spacing: 2px;
}

.sbDetails {
  height: 35%;
  width: 100%;
  margin-top: 12%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
}

.sbLink {
  height: 14.81%;
  width: 100%;
  margin-top: 8%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
  text-decoration: underline;
}
