.video {
  position: absolute;
  bottom: 1%;
  right: 1%;
  width: auto;
  height: 93%;

  border-style: solid;
  border-width: medium;
  border-color: var(--font-color-2);
  border-radius: 10px;
}
.video:focus {
  outline: 0px !important;
}
