.currentSub {
  width: 26%;
  margin-left: 37%;
  text-align: left;
  background-color: var(--font-color-2);
  padding: 10px 10px;
  margin-right: 5%;
  border-radius: 15px;
  color: white;
}

.subscriptions {

}

.highlightText {
  margin-left: 5px;
  color: #0000EE;
  cursor: pointer;
}

.subText {
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
  color: var(--font-color-1);
}

.subOptions {
  display: flex;
  flex-direction: row;
  max-height: 580px;
  margin-left: auto;
  margin-right: auto;
  min-width: 1000px;
  max-width: 1300px;
}

.subOne {
  display: flex;
  margin-left: 5%;
  margin-right: 2.5%;
}

.subThree {
  display: flex;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.subTwelve {
  display: flex;
  margin-left: 2.5%;
  margin-right: 5%;
}

.extraTxt {
  display: flex;
  width: 50%;
  margin-left: 25%;
  margin-top: 1%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 8px;
  border-radius: 15px;
  color: black;
  padding-bottom: 4px;
}

.addTxt {
  display: flex;
  margin-left: 2%;

  color: white;
}

.subContinueBtn {
  height: 40px;
  width: 20%;
  margin-left: 40%;
  margin-top: 3%;
  margin-bottom: 3%;
  color: var(--font-color-2);
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 10px;
  border-radius: 99999px;
  border-color: var(--font-color-2);
  background-color: transparent;
}
.subContinueBtn:hover {
  cursor: pointer;
  color: var(--font-color-3);
}
.subContinueBtn:active {
  background-color: var(--font-color-2);
  color: white;
}
.subContinueBtn:focus {
  outline: 0px;
}

.subSelected {
  border: 2px solid var(--font-color-2);
  padding: 1px 1px;
}

.errorNotice {
  position: absolute;
  z-index: 150;
  height: 190px;
  width: 40%;
  left: 30%;
  top: 25%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  text-align: center;
  overflow-y: scroll;
}

.trialNotice {
  position: absolute;
  z-index: 150;
  height: 160px;
  width: 40%;
  left: 30%;
  top: 25%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  text-align: center;
  overflow-y: scroll;
}

.success {
  font-family: TypoldLt;
  font-size: 16pt;
  text-align: center;
  background-color: #00cc00;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: var(--font-color-1);
}
