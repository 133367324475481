.layoutTitle {

}

.layouts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 1260px;
}

.layout {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  max-width: 33.333%;
  min-width: 300px;
  height: 100%;
}

.layoutTxt {
  display: flex;
  color: white;
  font-family: AvenirMed;
}

.layoutImg {
  display: flex;
  width: 300px;
  height: 240px;
  margin-bottom: 10px;
}

.layoutVideo {
  width: 99%;
  text-align: center;
}

.layoutBtn {
  height: 40px;
  width: 25%;
  /* color: white; */
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 9999px;
  /* background-color: transparent; */
}
/* .layoutBtn:active {
  background-color: rgba(250,250,250,.3);
}
.layoutBtn:focus {
  outline: 0px;
}
.layoutBtn:hover {
  color: rgba(250,250,250,.6);
} */
