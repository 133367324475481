.bbContainer {
  align-items: center;
  max-height: 100%;
  width: 100%;
}

.bbH {
  height: 14.81%;
  width: 100%;
  margin-top: 8%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldBold;
  letter-spacing: 2px;
}

.bbDetails {
  height: 35%;
  width: 100%;
  margin-top: 5%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
}

.bbBarcode-outer {
  min-height: 80%;
  width: 80%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 10px;
  border-radius: 10px;
  background-color: white;
}

.bbBarcode-inner {
  width: 100%;
}

.bbLink {
  height: 14.81%;
  width: 100%;
  margin-top: 2%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
  text-decoration: underline;
}
