.dfContainer {
  align-items: center;
  min-height: 95%;
  max-height: 100%;
  width: 100%;
  border: solid 2px black;
}

.dfEmptyTop {
  height: 9.87%;
  width: 100%;
}

.dfHighlight {
  height: 9.87%;
  width: 70%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-around;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
  font-family: TypoldBold;
  text-align: center;
  color: white;
  background-color: blue;
}

.dfBuffer1 {
  height: 6.17%;
  width: 100%;
}

.dfHours {
  height: 7.40%;
  width: 100%;
  color: white;
  font-family: TypoldBold;
  text-align: center;
}

.dfBuffer2 {
  height: 2.4%;
  width: 100%;
  /* background-color: rgba(0,0,0,.2); */
}

.dfMainText {
  height: 37.03%;
  width: 100%;
  text-align: center;
  font-family: TypoldBlk;
  color: white;
  letter-spacing: 3px;
}

.dfBuffer3 {
  height: 12.34%;
  width: 100%;
}

.dfH1 {
  height: 8%;
  width: 100%;
  margin-top: 4%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldMed;
  letter-spacing: 2px;
}

.dfH2 {
  height: 8%;
  width: 100%;
  margin-top: 4%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldMed;
  letter-spacing: 2px;
}
