hr {
  border-style: solid;
  border-width: 1px;
  color: white;
}

.accountText {
  color: white;
  font-family: TypoldReg;
  text-align: center;
  background-color: var(--font-color-2);
  padding: 10px 10px;
}

.accountContainer {
  margin-top: 3%;
  height: 300px;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 2%;
  margin-right: 2%;
  height:100% !important;
  text-align: left;
  background-color: var(--font-color-2);
  padding: 10px 20px;
  border-radius: 15px;
}

.contactTitle {
  font-family: TypoldBold;
  margin-bottom: 15px;
}

.contactBody {

}

.locationContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 2%;
  margin-right: 2%;
  height: 100% !important;
  text-align: right;
  background-color: var(--font-color-2);
  padding: 10px 20px;
  border-radius: 15px;
  overflow-y: scroll;
}

.locationTitle {
  font-family: TypoldBold;
  margin-bottom: 15px;
}

.locationBody {

}

.location {
  margin-bottom: 5%;
}

.subContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
  text-align: center;
  color: white;
  background-color: var(--font-color-2);
  padding: 10px 10px;
  border-radius: 15px;
}

.subTitle {
  font-family: TypoldBold;
  margin-bottom: 15px;
}

.subBody {

}

.manageSubBtn {
  height: 40px;
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 3px;
  margin-top: 5%;
  border-radius: 99999px;
  border-color: white !important;
  border-width: thin !important;
}
