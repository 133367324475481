@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-position: center;
  background-attachment: fixed;
  background-image: -webkit-linear-gradient(top left, #5e06f7, #ff00ae);
  background-image: linear-gradient(to bottom right, #5e06f7, #ff00ae);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}

@font-face {
  font-family: TypoldLt;
  src: url(/static/media/Avenir_Light.9336c835.ttf);
}
@font-face {
  font-family: AvenirMed;
  src: url(/static/media/Avenir_Medium.0fe148f8.ttf);
}
@font-face {
  font-family: AvenirBk;
  src: url(/static/media/Avenir_Black.aa91c02d.ttf);
}
@font-face {
  font-family: TypoldLt;
  src: url(/static/media/Typold_Light.f48308eb.otf);
}
@font-face {
  font-family: TypoldMed;
  src: url(/static/media/Typold_Medium.721feb34.otf);
}
@font-face {
  font-family: TypoldBold;
  src: url(/static/media/Typold_Bold.32d98bd0.ttf);
}
@font-face {
  font-family: TypoldBlk;
  src: url(/static/media/Typold_Black.dd1743b9.ttf);
}
@font-face {
  font-family: TypoldReg;
  src: url(/static/media/Typold_Regular.666a5481.otf);
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;

  --font-color-1: #595959;
  --font-color-2: #5000ff;
  --font-color-6: rgba(81, 0, 255, .6);
  --font-color-3: rgba(81, 0, 255, .3);
  --font-color-disabled: rgba(81,0,255,.5);
  --greyed-out: rgba(179, 179, 179);

  --location0: rgb(0, 51, 204);
  --location1: rgb(77, 121, 255);
  --location2: rgb(204, 0, 0);
  --location3: rgb(255, 102, 102);
  --location4: rgb(0, 153, 51);
  --location5: rgb(0, 255, 85);
  --location6: rgb(51, 51, 51);
  --location7: rgb(128, 128, 128);
}

.mobileNotice {
  position: absolute;
  z-index: 300;
  top: 20%;
  height: 40%;
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
  background-color: var(--bg-color);
  color: #595959;
  color: var(--font-color-1);
}

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}

.app {
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  margin: 0px 0px 0px 0px;
  background-position: center;
  background-attachment: fixed;
  background-color: white;
  /* background-image: linear-gradient(to bottom right, #5e06f7, #ff00ae); */
}

.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,.3);
}

.progressTxt {
  position: relative;
  top: 50%;
  height: 10%;
  width: 10%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: white;
  font-family: TypoldBold;
  font-size: 2em;
}

.page {
  margin-top: 30px;
  overflow: scroll;
}

.title {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  font-family: TypoldBold;
  color: #595959;
  color: var(--font-color-1);
  letter-spacing: 2px;
  font-size: 25pt;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 90%;
  top: 1%;
  left: 5%;
  padding: 5px 5px;
}

.all-btn {
  color: white;
  background-color: #5000ff;
  background-color: var(--font-color-2);
  border-width: 0px;
}
.all-btn:active {
  background-color: rgba(81, 0, 255, .6);
  background-color: var(--font-color-6);
}
.all-btn:hover {
  color: rgba(250,250,250,.6);
}
.all-btn:disabled {
  background-color: rgba(179, 179, 179);
  background-color: var(--greyed-out);
}
.all-btn:focus {
  outline: 0px;
}

.navBtn {
  height: 40px;
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 99999px;
}
.navBtn:disabled {
  display: none;
}

.navBuffer {
  flex: 10 1;
}

.backBtn {
  display: flex;
  flex: 1 1;
}
.logoutBtn {
  display: flex;
  flex: 1 1;
}

.btn {
  flex-grow: 2;
  margin-top: 5px;
  width: 100%;
  color: white;
  font-family: TypoldLt;
  font-size: 1.5em;
  letter-spacing: 2px;
  background-color: transparent;
  border-radius: 99999px;
}

.react-spinner {
  position: relative;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
}

.react-spinner_bar {
  -webkit-animation: react-spinner_spin 1.2s linear infinite;
  animation: react-spinner_spin 1.2s linear infinite;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  width: 20%;
  height: 7.8%;
  top: -3.9%;
  left: -10%;
}

.tutorialVideo {
  position: absolute;
  z-index: 50;
  bottom: 0;
  right: 0;
  height: 50%;
  width: 50%;
  text-align: right;
  padding-right: 5px;
  outline: 0px !important;
}

.videoBtn {
  border-radius: 99999px;
}

.success {
  font-family: TypoldLt;
  font-size: 16pt;
  text-align: center;
  background-color: #00cc00;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: #595959;
  border-color: var(--font-color-1);
}

.invalid {
  font-family: TypoldLt;
  font-size: 16pt;
  text-align: center;
  background-color: #ff0000;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: #595959;
  border-color: var(--font-color-1);
}

.terms {
  position: absolute;
  z-index: 200;
  height: 550px;
  width: 50%;
  left: 25%;
  top: 10%;
  padding: 15px;
  border-radius: 20px;
  border: 2px solid #595959;
  border: 2px solid var(--font-color-1);
  background-color: #f9f9f9;
  background-color: var(--bg-color);
  overflow-y: scroll;
}
.termsTxt {
  text-decoration: underline;
  color: white;
  cursor: pointer;
}

@keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@-webkit-keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {

}

.closeTerms {
  position: absolute;
  top: 10px;
  right: 30px;
  height: 10px;
  width: 30px;
  text-decoration: underline;
  color: #0000EE;
  cursor: pointer;
}

@font-face {
  font-family: AvenirLt;
  src: url(/static/media/Avenir_Light.9336c835.ttf);
}
@font-face {
  font-family: TypoldLt;
  src: url(/static/media/Typold_Light.f48308eb.otf);
}
@font-face {
  font-family: TypoldMed;
  src: url(/static/media/Typold_Medium.721feb34.otf);
}
@font-face {
  font-family: TypoldBold;
  src: url(/static/media/Typold_Bold.32d98bd0.ttf);
}

.signupBtn {
  height: 40px;
  width: 30%;
  margin-left: 35%;
  /* color: var(--font-color-2) !important; */
  /* border-color: var(--font-color-2) !important; */
  font-family: TypoldLt;
  font-size: 15px;
  letter-spacing: 2px;
  /* background-color: transparent; */
  border-radius: 99999px;
  margin-bottom: 10px;
}
/* .signupBtn:active {
  background-color: var(--font-color-3) !important;
}
.signupBtn:focus {
  outline: 0px;
}
.signupBtn:hover {
  color: var(--font-color-6) !important;
} */

.login {
  padding: 60px 0px;
  margin-top: 80px;
}

.login form {
  margin: 0 auto;
  max-width: 320px;
}

.loginLabel {
  color: var(--font-color-1) !important;
}

.loginBtn {
  color: var(--font-color-2);
}
.loginBtn:hover {
  color: white;
}
.loginBtn:focus {
  outline: 0px !important;
}

.loginVideo {
  width: 99%;
  margin-top: 20px;
  text-align: center;
}

.invalid {
  font-family: TypoldLt;
  font-size: 18pt;
  text-align: center;
  background-color: #ff6666;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: #ffffff;
}

.copyright {
  position: absolute;
  bottom: 10px;
  width: 20%;
  left: 40%;
  text-align: center;
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {

}

.signupForm {
  padding: 60px 0px;
}

.signupForm form {
  margin: 0 15%;
  max-width: 70%;
}

.signupLabel {
  color: var(--font-color-1) !important;
  font-size: 1.2em;
}

.signupAuth {
  width: 100%;
  margin-top: 5px;
  justify-content: center;
}

.signupSubmit {
  color: var(--font-color-2);
  width: 100%;
}
.signupSubmit:hover {
  color: white;
}
.signupSubmit:focus {
  outline: 0px !important;
}

.helpTxt {
  margin-left: 5px;
  text-decoration: underline;
  color: #0000EE;
  cursor: pointer;
}

.signupHelpBtn {
  height: 40px;
  width: 100%;
  margin-top: 10px;
  color: var(--font-color-2);
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 10px;
  border-radius: 99999px;
  border-color: var(--font-color-2);
  background-color: transparent;
}
.signupHelpBtn:hover {
  cursor: pointer;
  color: var(--font-color-3);
}
.signupHelpBtn:active {
  background-color: var(--font-color-2);
  color: white;
}
.signupHelpBtn:focus {
  outline: 0px;
}

.leftBtn {
  position: relative;
  left: -20px;
}
.rightBtn {
  position: relative;
  left: 20px;
}

.locationHelp {
  position: absolute;
  z-index: 100;
  height: 365px;
  width: 50%;
  left: 25%;
  top: 20%;
  padding-top: 5px;
  padding-right: 5px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  overflow-y: scroll;
}

.locationTitle {
  text-align: center;
  height: 10%;
  text-decoration: underline;
}

.addLocation {
  position: absolute;
  z-index: 150;
  height: 275px;
  width: 50%;
  left: 25%;
  top: 20%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  overflow-y: scroll;
}

.listTxt {
  font-size: 1.8em;
}

.signupTerms {
  text-align: center;
  margin-top: 20px;
}


.invalid {
  font-family: TypoldLt;
  font-size: 18pt;
  text-align: center;
  background-color: #ff6666;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: #ffffff;
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {

}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    /* color: var(--text-color);
    background: var(--bg-color); */
    color: var(--font-color-1);
    background: var(--bg-color);
    position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--neutral-color);
  border: 3px solid var(--border-color);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 10px 2px rgba(0,0,0,.2);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  -webkit-transition: .15s ease-out;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
  -webkit-transition: .25s ease-out;
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 8.5em;
  border-right: 1px solid var(--border-color);
  overflow: scroll;
  /* cursor: pointer; */
  background: var(--neutral-color);
  /* transition: 0.25s ease-out; */
}

.calendar .body .cell:hover {
  /* background: var(--bg-color);
  transition: 0.5s ease-out; */
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  -webkit-border-image: -webkit-linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
          border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  /* position: absolute;
  z-index: 10;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700; */

  position: absolute;
  height: 15%;
  width: 10%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 2px;
  z-index: 10;
  font-size: 82.5%;
  line-height: 1;
  top: .1em;
  right: .1em;
  font-weight: 700;
  border-radius: 99999px;
  background-color: white;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  /* font-weight: 700;
  line-height: 1; */
  /* color: var(--main-color); */
  /* opacity: 0; */
  /* font-size: 3em; */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* transition: .25s ease-out;
  letter-spacing: -.07em; */
}

.calendar .body .cell .bg .item {
  width: 100%;
  height: 18%;
}
.calendar .body .cell .bg .item:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,.2);
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
  /* opacity: 0.05;
  transition: .5s ease-in; */
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}

.container0 {
  background-color: var(--location0);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container1 {
  background-color: var(--location1);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container2 {
  background-color: var(--location2);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container3 {
  background-color: var(--location3);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container4 {
  background-color: var(--location4);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container5 {
  background-color: var(--location5);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container6 {
  background-color: var(--location6);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container7 {
  background-color: var(--location7);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  padding: 2px 3px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #ddd;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dealContainer {background-color: rgba(255, 0, 102, .3);}

.calendar-contain {
  position: absolute;
  right: 1%;
  top: 12%;
  height: 86%;
  width: 70%;
  background-color: transparent;
}

.accountMenu {
  position: absolute;
  left: 5%;
  top: 15%;
  height: 80%;
  width: 20%;
  display: flex;
  flex-direction: column;
}

.businessName {
  flex-grow: 1;
  font-family: TypoldMed;
  font-size: 3em;
  color: var(--font-color-1);
}

.menuBtnContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 5;
}

.homeVideo {
  width: 99%;
  margin-top: 20px;
  text-align: center;
}

.homeBtn {
  flex-grow: 2;
  margin-top: 8%;
  width: 100%;
  /* color: var(--font-color-2); */
  font-family: TypoldLt;
  font-size: 1.5em;
  letter-spacing: 2px;
  /* background-color: rgb(230, 230, 230); */
  border-radius: 99999px;
  /* border-color: transparent; */
}
/* .homeBtn:hover {
  color: rgba(250,250,250,.6);
}
.homeBtn:active {
  background-color: rgba(255,255,255,.4);
} */

.subNotice {
  position: absolute;
  z-index: 100;
  bottom: 0px;
  right: -5%;
  margin: 0px 0px;
  min-width: 42%;
  text-align: center;
  background-color: var(--font-color-2);
  padding: 10px 10px;
  margin-right: 5%;
  border-radius: 15px;
  color: white;
}

.pushNotice {
  position: absolute;
  z-index: 150;
  height: 220px;
  width: 40%;
  left: 30%;
  top: 25%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  text-align: center;
  overflow-y: scroll;
}

.createNotice {
  position: absolute;
  z-index: 170;
  height: 180px;
  width: 40%;
  left: 30%;
  top: 25%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  text-align: center;
  overflow-y: scroll;
}

.subscribeNowBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.subscribeNowBtn {
  display: flex;
  height: 40px;
  width: 20%;
  /* margin-left: 10%;
  margin-top: 3%;
  margin-bottom: 3%; */
  color: var(--font-color-2);
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 99999px;
  border-color: var(--font-color-2);
  background-color: white;
}
.subscribeNowBtn:hover {
  cursor: pointer;
  color: var(--font-color-3);
}
.subscribeNowBtn:active {
  background-color: var(--font-color-6);
  color: white;
}
.subscribeNowBtn:focus {
  outline: 0px;
}

.pushCloseBtn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.createNoticeBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 15px;
}

.lineSpace {
  margin-top: 10px;
  font-family: TypoldMed;
}

@font-face {
  font-family: AvenirLt;
  src: url(/static/media/Avenir_Light.9336c835.ttf);
}
@font-face {
  font-family: TypoldLt;
  src: url(/static/media/Typold_Light.f48308eb.otf);
}
@font-face {
  font-family: TypoldMed;
  src: url(/static/media/Typold_Medium.721feb34.otf);
}
@font-face {
  font-family: TypoldBold;
  src: url(/static/media/Typold_Bold.32d98bd0.ttf);
}

.invalid {
  font-family: TypoldLt;
  font-size: 18pt;
  text-align: center;
  background-color: #ff6666;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: #ffffff;
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {

}

.layoutTitle {

}

.layouts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 1260px;
}

.layout {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  max-width: 33.333%;
  min-width: 300px;
  height: 100%;
}

.layoutTxt {
  display: flex;
  color: white;
  font-family: AvenirMed;
}

.layoutImg {
  display: flex;
  width: 300px;
  height: 240px;
  margin-bottom: 10px;
}

.layoutVideo {
  width: 99%;
  text-align: center;
}

.layoutBtn {
  height: 40px;
  width: 25%;
  /* color: white; */
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 9999px;
  /* background-color: transparent; */
}
/* .layoutBtn:active {
  background-color: rgba(250,250,250,.3);
}
.layoutBtn:focus {
  outline: 0px;
}
.layoutBtn:hover {
  color: rgba(250,250,250,.6);
} */

.backsContainer {
  height: 90%;
  width: 100%;
  overflow: scroll;
  background-color: white;
  max-width: 1280px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.backsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 265px;
}

.backImg {
  display: flex;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
}

.backSelected {
  border: solid 2px;
  border-radius: 10px;
  border-color: var(--font-color-2);
}

.backsBtn {
  height: 50px;
  width: 10%;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 45%;
  /* color: white; */
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 9999px;
  /* background-color: transparent; */
}
/* .backsBtn:active {
  background-color: rgba(250,250,250,.3);
}
.backsBtn:focus {
  outline: 0px;
}
.backsBtn:hover {
  color: rgba(250,250,250,.6);
} */

.inputHeader {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.inputVideo {
  width: 99%;
  text-align: center;
}

.inputForm {
  padding: 20px 20px;
  width: 60%;
}

.inputForm form {
  margin: 0 auto;
  max-width: 820px;
}

.inputLabel {
  color: var(--font-color-1) !important;
  font-size: 1.2em;
}

.inputSubmit {
  color: var(--font-color-2);
  width: 100%;
}
.inputSubmit:hover {
  color: white;
}
.inputSubmit:focus {
  outline: 0px !important;
}

.inputExtra {
  margin-right: 10px;
  top: 3px;
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 100%;
}

.previewTxt {
  display: flex;
  color: var(--font-color-1);
  font-family: AvenirMed;
}

.previewImg {
  display: flex;
  max-width: 70%;
  margin-bottom: 10px;
}

.previewBackground {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  z-index: 0;

}

.previewForeground {
  position: relative;
  z-index: 20;
  min-height: 100%;
  min-width: 100%;
  left: -100%;
}

.sfContainer {
  align-items: center;
  min-height: 95%;
  max-height: 100%;
  width: 100%;
  border: solid 2px black;
}

.sfEmptyTop {
  height: 9.87%;
  width: 100%;
}

.sfHighlight {
  height: 9.87%;
  width: 70%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-around;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
  font-family: TypoldBold;
  text-align: center;
  color: white;
  background-color: blue;
}

.sfBuffer1 {
  height: 6.17%;
  width: 100%;
}

.sfHours {
  height: 7.40%;
  width: 100%;
  color: white;
  font-family: TypoldBold;
  text-align: center;
}

.sfBuffer2 {
  height: 2.4%;
  width: 100%;
  /* background-color: rgba(0,0,0,.2); */
}

.sfMainText {
  height: 37.03%;
  width: 100%;
  text-align: center;
  font-family: TypoldBlk;
  color: white;
  letter-spacing: 3px;
  /* background-color: rgba(0,0,0,.2); */
}

.sfBuffer3 {
  height: 12.34%;
  width: 100%;
  background-color: rgba(0,0,0,.2);
}

.sfH {
  height: 14.81%;
  width: 100%;
  margin-top: 10%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldMed;
  letter-spacing: 2px;
}

.sbContainer {
  align-items: center;
  max-height: 100%;
  width: 100%;
}

.sbH {
  height: 14.81%;
  width: 100%;
  margin-top: 10%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldBold;
  letter-spacing: 2px;
}

.sbDetails {
  height: 35%;
  width: 100%;
  margin-top: 12%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
}

.sbLink {
  height: 14.81%;
  width: 100%;
  margin-top: 8%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
  text-decoration: underline;
}

.dfContainer {
  align-items: center;
  min-height: 95%;
  max-height: 100%;
  width: 100%;
  border: solid 2px black;
}

.dfEmptyTop {
  height: 9.87%;
  width: 100%;
}

.dfHighlight {
  height: 9.87%;
  width: 70%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-around;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
  font-family: TypoldBold;
  text-align: center;
  color: white;
  background-color: blue;
}

.dfBuffer1 {
  height: 6.17%;
  width: 100%;
}

.dfHours {
  height: 7.40%;
  width: 100%;
  color: white;
  font-family: TypoldBold;
  text-align: center;
}

.dfBuffer2 {
  height: 2.4%;
  width: 100%;
  /* background-color: rgba(0,0,0,.2); */
}

.dfMainText {
  height: 37.03%;
  width: 100%;
  text-align: center;
  font-family: TypoldBlk;
  color: white;
  letter-spacing: 3px;
}

.dfBuffer3 {
  height: 12.34%;
  width: 100%;
}

.dfH1 {
  height: 8%;
  width: 100%;
  margin-top: 4%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldMed;
  letter-spacing: 2px;
}

.dfH2 {
  height: 8%;
  width: 100%;
  margin-top: 4%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldMed;
  letter-spacing: 2px;
}

.dbContainer {
  align-items: center;
  max-height: 100%;
  width: 100%;
}

.dbH1 {
  height: 14.81%;
  width: 100%;
  margin-top: 10%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldBold;
  letter-spacing: 2px;
}

.dbDetails1 {
  height: 35%;
  width: 100%;
  margin-top: 5%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
}

.dbH2 {
  height: 14.81%;
  width: 100%;
  margin-top: 8%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldBold;
  letter-spacing: 2px;
}

.dbDetails2 {
  height: 35%;
  width: 100%;
  margin-top: 5%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
}

.bfContainer {
  align-items: center;
  min-height: 95%;
  max-height: 100%;
  width: 100%;
  border: solid 2px black;
}

.bfEmptyTop {
  height: 9.87%;
  width: 100%;
}

.bfHighlight {
  height: 9.87%;
  width: 70%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-around;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
  font-family: TypoldBold;
  text-align: center;
  color: white;
  background-color: blue;
}

.bfBuffer1 {
  height: 6.17%;
  width: 100%;
}

.bfHours {
  height: 7.40%;
  width: 100%;
  color: white;
  font-family: TypoldBold;
  text-align: center;
}

.bfBuffer2 {
  height: 2.4%;
  width: 100%;
  /* background-color: rgba(0,0,0,.2); */
}

.bfMainText {
  height: 37.03%;
  width: 100%;
  text-align: center;
  font-family: TypoldBlk;
  color: white;
  letter-spacing: 3px;
  /* background-color: rgba(0,0,0,.2); */
}

.bfBuffer3 {
  height: 12.34%;
  width: 100%;
  background-color: rgba(0,0,0,.2);
}

.bfH {
  height: 14.81%;
  width: 100%;
  margin-top: 10%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldMed;
  letter-spacing: 2px;
}

.bbContainer {
  align-items: center;
  max-height: 100%;
  width: 100%;
}

.bbH {
  height: 14.81%;
  width: 100%;
  margin-top: 8%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldBold;
  letter-spacing: 2px;
}

.bbDetails {
  height: 35%;
  width: 100%;
  margin-top: 5%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
}

.bbBarcode-outer {
  min-height: 80%;
  width: 80%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 10px;
  border-radius: 10px;
  background-color: white;
}

.bbBarcode-inner {
  width: 100%;
}

.bbLink {
  height: 14.81%;
  width: 100%;
  margin-top: 2%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
  text-decoration: underline;
}

/* .inputHeader {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.inputForm {
  padding: 20px 20px;
  width: 60%;
}

.inputForm form {
  margin: 0 auto;
  max-width: 820px;
}

.inputLabel {
  color: var(--font-color-1) !important;
  font-size: 1.2em;
}

.inputSubmit {
  color: var(--font-color-2);
  width: 100%;
}
.inputSubmit:hover {
  color: white;
}

.inputExtra {
  margin-right: 10px;
  top: 3px;
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 100%;
}

.previewTxt {
  display: flex;
  color: black;
  font-family: AvenirMed;
}

.previewImg {
  display: flex;
  max-width: 70%;
  margin-bottom: 10px;
}

.previewBackground {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  z-index: 0;

}

.previewForeground {
  position: relative;
  z-index: 20;
  min-height: 100%;
  min-width: 100%;
  left: -100%;
} */

hr {
  border-style: solid;
  border-width: 1px;
  color: white;
}

.accountText {
  color: white;
  font-family: TypoldReg;
  text-align: center;
  background-color: var(--font-color-2);
  padding: 10px 10px;
}

.accountContainer {
  margin-top: 3%;
  height: 300px;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 2%;
  margin-right: 2%;
  height:100% !important;
  text-align: left;
  background-color: var(--font-color-2);
  padding: 10px 20px;
  border-radius: 15px;
}

.contactTitle {
  font-family: TypoldBold;
  margin-bottom: 15px;
}

.contactBody {

}

.locationContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 2%;
  margin-right: 2%;
  height: 100% !important;
  text-align: right;
  background-color: var(--font-color-2);
  padding: 10px 20px;
  border-radius: 15px;
  overflow-y: scroll;
}

.locationTitle {
  font-family: TypoldBold;
  margin-bottom: 15px;
}

.locationBody {

}

.location {
  margin-bottom: 5%;
}

.subContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
  text-align: center;
  color: white;
  background-color: var(--font-color-2);
  padding: 10px 10px;
  border-radius: 15px;
}

.subTitle {
  font-family: TypoldBold;
  margin-bottom: 15px;
}

.subBody {

}

.manageSubBtn {
  height: 40px;
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 3px;
  margin-top: 5%;
  border-radius: 99999px;
  border-color: white !important;
  border-width: thin !important;
}

.cardField {
  margin-bottom: 15px;
  background-color: white;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #CCC;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  line-height: 1.3333333;
}

.billing {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  text-align: center;
}

.billingLabel {
  width: 100%;
  text-align: left;
}

.currentSub {
  width: 26%;
  margin-left: 37%;
  text-align: left;
  background-color: var(--font-color-2);
  padding: 10px 10px;
  margin-right: 5%;
  border-radius: 15px;
  color: white;
}

.subscriptions {

}

.highlightText {
  margin-left: 5px;
  color: #0000EE;
  cursor: pointer;
}

.subText {
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
  color: var(--font-color-1);
}

.subOptions {
  display: flex;
  flex-direction: row;
  max-height: 580px;
  margin-left: auto;
  margin-right: auto;
  min-width: 1000px;
  max-width: 1300px;
}

.subOne {
  display: flex;
  margin-left: 5%;
  margin-right: 2.5%;
}

.subThree {
  display: flex;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.subTwelve {
  display: flex;
  margin-left: 2.5%;
  margin-right: 5%;
}

.extraTxt {
  display: flex;
  width: 50%;
  margin-left: 25%;
  margin-top: 1%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 8px;
  border-radius: 15px;
  color: black;
  padding-bottom: 4px;
}

.addTxt {
  display: flex;
  margin-left: 2%;

  color: white;
}

.subContinueBtn {
  height: 40px;
  width: 20%;
  margin-left: 40%;
  margin-top: 3%;
  margin-bottom: 3%;
  color: var(--font-color-2);
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 10px;
  border-radius: 99999px;
  border-color: var(--font-color-2);
  background-color: transparent;
}
.subContinueBtn:hover {
  cursor: pointer;
  color: var(--font-color-3);
}
.subContinueBtn:active {
  background-color: var(--font-color-2);
  color: white;
}
.subContinueBtn:focus {
  outline: 0px;
}

.subSelected {
  border: 2px solid var(--font-color-2);
  padding: 1px 1px;
}

.errorNotice {
  position: absolute;
  z-index: 150;
  height: 190px;
  width: 40%;
  left: 30%;
  top: 25%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  text-align: center;
  overflow-y: scroll;
}

.trialNotice {
  position: absolute;
  z-index: 150;
  height: 160px;
  width: 40%;
  left: 30%;
  top: 25%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  text-align: center;
  overflow-y: scroll;
}

.success {
  font-family: TypoldLt;
  font-size: 16pt;
  text-align: center;
  background-color: #00cc00;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: var(--font-color-1);
}

.video {
  position: absolute;
  bottom: 1%;
  right: 1%;
  width: auto;
  height: 93%;

  border-style: solid;
  border-width: medium;
  border-color: var(--font-color-2);
  border-radius: 10px;
}
.video:focus {
  outline: 0px !important;
}

