.container0 {
  background-color: var(--location0);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container1 {
  background-color: var(--location1);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container2 {
  background-color: var(--location2);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container3 {
  background-color: var(--location3);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container4 {
  background-color: var(--location4);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container5 {
  background-color: var(--location5);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container6 {
  background-color: var(--location6);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}
.container7 {
  background-color: var(--location7);
  padding-left: 5px;
  font-family: TypoldMed;
  letter-spacing: .5px;
  color: white;
  border: .5px solid white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  padding: 2px 3px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #ddd;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dealContainer {background-color: rgba(255, 0, 102, .3);}
