.dbContainer {
  align-items: center;
  max-height: 100%;
  width: 100%;
}

.dbH1 {
  height: 14.81%;
  width: 100%;
  margin-top: 10%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldBold;
  letter-spacing: 2px;
}

.dbDetails1 {
  height: 35%;
  width: 100%;
  margin-top: 5%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
}

.dbH2 {
  height: 14.81%;
  width: 100%;
  margin-top: 8%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldBold;
  letter-spacing: 2px;
}

.dbDetails2 {
  height: 35%;
  width: 100%;
  margin-top: 5%;
  text-align: center;
  color: white;
  font-family: TypoldReg;
  letter-spacing: 2px;
}
