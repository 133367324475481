.cardField {
  margin-bottom: 15px;
  background-color: white;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #CCC;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  line-height: 1.3333333;
}

.billing {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  text-align: center;
}

.billingLabel {
  width: 100%;
  text-align: left;
}
