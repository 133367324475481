.signupForm {
  padding: 60px 0px;
}

.signupForm form {
  margin: 0 15%;
  max-width: 70%;
}

.signupLabel {
  color: var(--font-color-1) !important;
  font-size: 1.2em;
}

.signupAuth {
  width: 100%;
  margin-top: 5px;
  justify-content: center;
}

.signupSubmit {
  color: var(--font-color-2);
  width: 100%;
}
.signupSubmit:hover {
  color: white;
}
.signupSubmit:focus {
  outline: 0px !important;
}

.helpTxt {
  margin-left: 5px;
  text-decoration: underline;
  color: #0000EE;
  cursor: pointer;
}

.signupHelpBtn {
  height: 40px;
  width: 100%;
  margin-top: 10px;
  color: var(--font-color-2);
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 10px;
  border-radius: 99999px;
  border-color: var(--font-color-2);
  background-color: transparent;
}
.signupHelpBtn:hover {
  cursor: pointer;
  color: var(--font-color-3);
}
.signupHelpBtn:active {
  background-color: var(--font-color-2);
  color: white;
}
.signupHelpBtn:focus {
  outline: 0px;
}

.leftBtn {
  position: relative;
  left: -20px;
}
.rightBtn {
  position: relative;
  left: 20px;
}

.locationHelp {
  position: absolute;
  z-index: 100;
  height: 365px;
  width: 50%;
  left: 25%;
  top: 20%;
  padding-top: 5px;
  padding-right: 5px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  overflow-y: scroll;
}

.locationTitle {
  text-align: center;
  height: 10%;
  text-decoration: underline;
}

.addLocation {
  position: absolute;
  z-index: 150;
  height: 275px;
  width: 50%;
  left: 25%;
  top: 20%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  overflow-y: scroll;
}

.listTxt {
  font-size: 1.8em;
}

.signupTerms {
  text-align: center;
  margin-top: 20px;
}


.invalid {
  font-family: TypoldLt;
  font-size: 18pt;
  text-align: center;
  background-color: #ff6666;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: #ffffff;
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {

}
