@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@font-face {
  font-family: TypoldLt;
  src: url(./assets/fonts/Avenir_Light.ttf);
}
@font-face {
  font-family: AvenirMed;
  src: url(./assets/fonts/Avenir_Medium.ttf);
}
@font-face {
  font-family: AvenirBk;
  src: url(./assets/fonts/Avenir_Black.ttf);
}
@font-face {
  font-family: TypoldLt;
  src: url(./assets/fonts/Typold_Light.otf);
}
@font-face {
  font-family: TypoldMed;
  src: url(./assets/fonts/Typold_Medium.otf);
}
@font-face {
  font-family: TypoldBold;
  src: url(./assets/fonts/Typold_Bold.ttf);
}
@font-face {
  font-family: TypoldBlk;
  src: url(./assets/fonts/Typold_Black.ttf);
}
@font-face {
  font-family: TypoldReg;
  src: url(./assets/fonts/Typold_Regular.otf);
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;

  --font-color-1: #595959;
  --font-color-2: #5000ff;
  --font-color-6: rgba(81, 0, 255, .6);
  --font-color-3: rgba(81, 0, 255, .3);
  --font-color-disabled: rgba(81,0,255,.5);
  --greyed-out: rgba(179, 179, 179);

  --location0: rgb(0, 51, 204);
  --location1: rgb(77, 121, 255);
  --location2: rgb(204, 0, 0);
  --location3: rgb(255, 102, 102);
  --location4: rgb(0, 153, 51);
  --location5: rgb(0, 255, 85);
  --location6: rgb(51, 51, 51);
  --location7: rgb(128, 128, 128);
}

.mobileNotice {
  position: absolute;
  z-index: 300;
  top: 20%;
  height: 40%;
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: var(--bg-color);
  color: var(--font-color-1);
}

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

.app {
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  margin: 0px 0px 0px 0px;
  background-position: center;
  background-attachment: fixed;
  background-color: white;
  /* background-image: linear-gradient(to bottom right, #5e06f7, #ff00ae); */
}

.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,.3);
}

.progressTxt {
  position: relative;
  top: 50%;
  height: 10%;
  width: 10%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: white;
  font-family: TypoldBold;
  font-size: 2em;
}

.page {
  margin-top: 30px;
  overflow: scroll;
}

.title {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  font-family: TypoldBold;
  color: var(--font-color-1);
  letter-spacing: 2px;
  font-size: 25pt;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 90%;
  top: 1%;
  left: 5%;
  padding: 5px 5px;
}

.all-btn {
  color: white;
  background-color: var(--font-color-2);
  border-width: 0px;
}
.all-btn:active {
  background-color: var(--font-color-6);
}
.all-btn:hover {
  color: rgba(250,250,250,.6);
}
.all-btn:disabled {
  background-color: var(--greyed-out);
}
.all-btn:focus {
  outline: 0px;
}

.navBtn {
  height: 40px;
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 99999px;
}
.navBtn:disabled {
  display: none;
}

.navBuffer {
  flex: 10;
}

.backBtn {
  display: flex;
  flex: 1;
}
.logoutBtn {
  display: flex;
  flex: 1;
}

.btn {
  flex-grow: 2;
  margin-top: 5px;
  width: 100%;
  color: white;
  font-family: TypoldLt;
  font-size: 1.5em;
  letter-spacing: 2px;
  background-color: transparent;
  border-radius: 99999px;
}

.react-spinner {
  position: relative;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
}

.react-spinner_bar {
  -webkit-animation: react-spinner_spin 1.2s linear infinite;
  -moz-animation: react-spinner_spin 1.2s linear infinite;
  animation: react-spinner_spin 1.2s linear infinite;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  width: 20%;
  height: 7.8%;
  top: -3.9%;
  left: -10%;
}

.tutorialVideo {
  position: absolute;
  z-index: 50;
  bottom: 0;
  right: 0;
  height: 50%;
  width: 50%;
  text-align: right;
  padding-right: 5px;
  outline: 0px !important;
}

.videoBtn {
  border-radius: 99999px;
}

.success {
  font-family: TypoldLt;
  font-size: 16pt;
  text-align: center;
  background-color: #00cc00;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: var(--font-color-1);
}

.invalid {
  font-family: TypoldLt;
  font-size: 16pt;
  text-align: center;
  background-color: #ff0000;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: var(--font-color-1);
}

.terms {
  position: absolute;
  z-index: 200;
  height: 550px;
  width: 50%;
  left: 25%;
  top: 10%;
  padding: 15px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  overflow-y: scroll;
}
.termsTxt {
  text-decoration: underline;
  color: white;
  cursor: pointer;
}

@keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@-moz-keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@-webkit-keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {

}
