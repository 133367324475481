.backsContainer {
  height: 90%;
  width: 100%;
  overflow: scroll;
  background-color: white;
  max-width: 1280px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.backsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 265px;
}

.backImg {
  display: flex;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
}

.backSelected {
  border: solid 2px;
  border-radius: 10px;
  border-color: var(--font-color-2);
}

.backsBtn {
  height: 50px;
  width: 10%;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 45%;
  /* color: white; */
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 9999px;
  /* background-color: transparent; */
}
/* .backsBtn:active {
  background-color: rgba(250,250,250,.3);
}
.backsBtn:focus {
  outline: 0px;
}
.backsBtn:hover {
  color: rgba(250,250,250,.6);
} */
