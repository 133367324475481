/* GENERAL */

* {
  box-sizing: border-box;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    /* color: var(--text-color);
    background: var(--bg-color); */
    color: var(--font-color-1);
    background: var(--bg-color);
    position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--neutral-color);
  border: 3px solid var(--border-color);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 10px 2px rgba(0,0,0,.2);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 8.5em;
  border-right: 1px solid var(--border-color);
  overflow: scroll;
  /* cursor: pointer; */
  background: var(--neutral-color);
  /* transition: 0.25s ease-out; */
}

.calendar .body .cell:hover {
  /* background: var(--bg-color);
  transition: 0.5s ease-out; */
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  /* position: absolute;
  z-index: 10;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700; */

  position: absolute;
  height: 15%;
  width: 10%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 2px;
  z-index: 10;
  font-size: 82.5%;
  line-height: 1;
  top: .1em;
  right: .1em;
  font-weight: 700;
  border-radius: 99999px;
  background-color: white;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  /* font-weight: 700;
  line-height: 1; */
  /* color: var(--main-color); */
  /* opacity: 0; */
  /* font-size: 3em; */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* transition: .25s ease-out;
  letter-spacing: -.07em; */
}

.calendar .body .cell .bg .item {
  width: 100%;
  height: 18%;
}
.calendar .body .cell .bg .item:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,.2);
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
  /* opacity: 0.05;
  transition: .5s ease-in; */
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}
