@font-face {
  font-family: AvenirLt;
  src: url(../../assets/fonts/Avenir_Light.ttf);
}
@font-face {
  font-family: TypoldLt;
  src: url(../../assets/fonts/Typold_Light.otf);
}
@font-face {
  font-family: TypoldMed;
  src: url(../../assets/fonts/Typold_Medium.otf);
}
@font-face {
  font-family: TypoldBold;
  src: url(../../assets/fonts/Typold_Bold.ttf);
}

.invalid {
  font-family: TypoldLt;
  font-size: 18pt;
  text-align: center;
  background-color: #ff6666;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: #ffffff;
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {

}
