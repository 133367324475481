.bfContainer {
  align-items: center;
  min-height: 95%;
  max-height: 100%;
  width: 100%;
  border: solid 2px black;
}

.bfEmptyTop {
  height: 9.87%;
  width: 100%;
}

.bfHighlight {
  height: 9.87%;
  width: 70%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-around;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 10px;
  font-family: TypoldBold;
  text-align: center;
  color: white;
  background-color: blue;
}

.bfBuffer1 {
  height: 6.17%;
  width: 100%;
}

.bfHours {
  height: 7.40%;
  width: 100%;
  color: white;
  font-family: TypoldBold;
  text-align: center;
}

.bfBuffer2 {
  height: 2.4%;
  width: 100%;
  /* background-color: rgba(0,0,0,.2); */
}

.bfMainText {
  height: 37.03%;
  width: 100%;
  text-align: center;
  font-family: TypoldBlk;
  color: white;
  letter-spacing: 3px;
  /* background-color: rgba(0,0,0,.2); */
}

.bfBuffer3 {
  height: 12.34%;
  width: 100%;
  background-color: rgba(0,0,0,.2);
}

.bfH {
  height: 14.81%;
  width: 100%;
  margin-top: 10%;
  justify-content: space-around;
  text-align: center;
  color: white;
  font-family: TypoldMed;
  letter-spacing: 2px;
}
