.inputHeader {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.inputVideo {
  width: 99%;
  text-align: center;
}

.inputForm {
  padding: 20px 20px;
  width: 60%;
}

.inputForm form {
  margin: 0 auto;
  max-width: 820px;
}

.inputLabel {
  color: var(--font-color-1) !important;
  font-size: 1.2em;
}

.inputSubmit {
  color: var(--font-color-2);
  width: 100%;
}
.inputSubmit:hover {
  color: white;
}
.inputSubmit:focus {
  outline: 0px !important;
}

.inputExtra {
  margin-right: 10px;
  top: 3px;
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 100%;
}

.previewTxt {
  display: flex;
  color: var(--font-color-1);
  font-family: AvenirMed;
}

.previewImg {
  display: flex;
  max-width: 70%;
  margin-bottom: 10px;
}

.previewBackground {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  z-index: 0;

}

.previewForeground {
  position: relative;
  z-index: 20;
  min-height: 100%;
  min-width: 100%;
  left: -100%;
}
