@font-face {
  font-family: AvenirLt;
  src: url(../../assets/fonts/Avenir_Light.ttf);
}
@font-face {
  font-family: TypoldLt;
  src: url(../../assets/fonts/Typold_Light.otf);
}
@font-face {
  font-family: TypoldMed;
  src: url(../../assets/fonts/Typold_Medium.otf);
}
@font-face {
  font-family: TypoldBold;
  src: url(../../assets/fonts/Typold_Bold.ttf);
}

.signupBtn {
  height: 40px;
  width: 30%;
  margin-left: 35%;
  /* color: var(--font-color-2) !important; */
  /* border-color: var(--font-color-2) !important; */
  font-family: TypoldLt;
  font-size: 15px;
  letter-spacing: 2px;
  /* background-color: transparent; */
  border-radius: 99999px;
  margin-bottom: 10px;
}
/* .signupBtn:active {
  background-color: var(--font-color-3) !important;
}
.signupBtn:focus {
  outline: 0px;
}
.signupBtn:hover {
  color: var(--font-color-6) !important;
} */

.login {
  padding: 60px 0px;
  margin-top: 80px;
}

.login form {
  margin: 0 auto;
  max-width: 320px;
}

.loginLabel {
  color: var(--font-color-1) !important;
}

.loginBtn {
  color: var(--font-color-2);
}
.loginBtn:hover {
  color: white;
}
.loginBtn:focus {
  outline: 0px !important;
}

.loginVideo {
  width: 99%;
  margin-top: 20px;
  text-align: center;
}

.invalid {
  font-family: TypoldLt;
  font-size: 18pt;
  text-align: center;
  background-color: #ff6666;

  border-radius: 10px;
  border-style: solid;
  border-width: thin;
  border-color: #ffffff;
}

.copyright {
  position: absolute;
  bottom: 10px;
  width: 20%;
  left: 40%;
  text-align: center;
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {

}
