.closeTerms {
  position: absolute;
  top: 10px;
  right: 30px;
  height: 10px;
  width: 30px;
  text-decoration: underline;
  color: #0000EE;
  cursor: pointer;
}
