.calendar-contain {
  position: absolute;
  right: 1%;
  top: 12%;
  height: 86%;
  width: 70%;
  background-color: transparent;
}

.accountMenu {
  position: absolute;
  left: 5%;
  top: 15%;
  height: 80%;
  width: 20%;
  display: flex;
  flex-direction: column;
}

.businessName {
  flex-grow: 1;
  font-family: TypoldMed;
  font-size: 3em;
  color: var(--font-color-1);
}

.menuBtnContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 5;
}

.homeVideo {
  width: 99%;
  margin-top: 20px;
  text-align: center;
}

.homeBtn {
  flex-grow: 2;
  margin-top: 8%;
  width: 100%;
  /* color: var(--font-color-2); */
  font-family: TypoldLt;
  font-size: 1.5em;
  letter-spacing: 2px;
  /* background-color: rgb(230, 230, 230); */
  border-radius: 99999px;
  /* border-color: transparent; */
}
/* .homeBtn:hover {
  color: rgba(250,250,250,.6);
}
.homeBtn:active {
  background-color: rgba(255,255,255,.4);
} */

.subNotice {
  position: absolute;
  z-index: 100;
  bottom: 0px;
  right: -5%;
  margin: 0px 0px;
  min-width: 42%;
  text-align: center;
  background-color: var(--font-color-2);
  padding: 10px 10px;
  margin-right: 5%;
  border-radius: 15px;
  color: white;
}

.pushNotice {
  position: absolute;
  z-index: 150;
  height: 220px;
  width: 40%;
  left: 30%;
  top: 25%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  text-align: center;
  overflow-y: scroll;
}

.createNotice {
  position: absolute;
  z-index: 170;
  height: 180px;
  width: 40%;
  left: 30%;
  top: 25%;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid var(--font-color-1);
  background-color: var(--bg-color);
  text-align: center;
  overflow-y: scroll;
}

.subscribeNowBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.subscribeNowBtn {
  display: flex;
  height: 40px;
  width: 20%;
  /* margin-left: 10%;
  margin-top: 3%;
  margin-bottom: 3%; */
  color: var(--font-color-2);
  font-family: TypoldLt;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 99999px;
  border-color: var(--font-color-2);
  background-color: white;
}
.subscribeNowBtn:hover {
  cursor: pointer;
  color: var(--font-color-3);
}
.subscribeNowBtn:active {
  background-color: var(--font-color-6);
  color: white;
}
.subscribeNowBtn:focus {
  outline: 0px;
}

.pushCloseBtn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.createNoticeBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 15px;
}

.lineSpace {
  margin-top: 10px;
  font-family: TypoldMed;
}
